<template>
  <!-- LIST DATA FAVORITE ADS -->
  <div class="list-category list-cate-like"
       :class="classTypeColor"
       v-if="$route.name === 'Profile.FavoriteAds'">
    <router-link :to="{ name: 'AdvertisingDetail', params: { id: item.advertise_id || 1 } }"
                 class="block-category"
                 v-for="(item, index) in list"
                 :key="index">
      <!-- MARK COMPLETED -->
      <img v-if="item.advertise.mission_completed && item.advertise.mission_completed.length"
           class="mark-cate" src="../assets/images/mark-complete.svg"
           alt="mark">

      <img :src="(item.advertise.file ? item.advertise.file.path : '') | imageEmpty" alt="">

      <!-- content category -->
      <div class="content-cate">
        <h4 class="line-clamp-02">{{ item.advertise.title }}</h4>
        <span class="description line-clamp-01">{{ item.advertise.list_description }}</span>
        <div class="point-back">
          <template v-if="item.advertise.is_campaign === 1
                      && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
            <p v-if="item.advertise.point_type === 2">通常 {{ item.advertise.normal_pointback_rate | numberWithCommas(',', '%P')  }}</p>

            <p v-else>通常 {{ item.advertise.normal_pointback_amount | numberWithCommas(',', 'P')  }}</p>
          </template>

          <strong v-if="item.advertise.is_campaign === 1
                      && checkDateBetween(item.advertise.campaign_start_datetime, item.advertise.campaign_end_datetime)">
            <template v-if="item.advertise.point_type === 2">{{ item.advertise.campaign_pointback_rate | numberWithCommas(',')  }}<span>%</span>P</template>
            <template v-else>{{ item.advertise.campaign_pointback_amount | numberWithCommas(',', 'P') }}</template>
          </strong>
          <strong v-else>
            <template v-if="item.advertise.point_type === 2">{{ item.advertise.normal_pointback_rate | numberWithCommas(',')  }}<span>%</span>P</template>
            <template v-else>{{ item.advertise.normal_pointback_amount | numberWithCommas(',', 'P') }}</template>
          </strong>
        </div>
      </div>
    </router-link>
  </div>

  <!-- LIST DATA CATEGORY -->
  <div class="list-category"
       :class="classTypeColor"
       v-else>
    <router-link :to="{ name: 'AdvertisingDetail', params: { id: item.id || 1 } }"
                 class="block-category"
                 v-for="(item, index) in list"
                 :key="index">
      <!-- MARK COMPLETED -->
      <img v-if="item.mission_completed && item.mission_completed.length"
           class="mark-cate" src="../assets/images/mark-complete.svg"
           alt="mark">

      <img :src="(item.file ? item.file.path : '') | imageEmpty" alt="">

      <!-- content category -->
      <div class="content-cate">
        <h4 class="line-clamp-02">{{ item.title }}</h4>
        <span class="description line-clamp-01">{{ item.list_description }}</span>
        <div class="point-back">
          <template v-if="item.is_campaign === 1
                      && checkDateBetween(item.campaign_start_datetime, item.campaign_end_datetime)">
            <p v-if="item.point_type === 2">通常 {{ item.normal_pointback_rate | numberWithCommas(',', '%P') }}</p>

            <p v-else>通常 {{ item.normal_pointback_amount | numberWithCommas(',', 'P') }}</p>
          </template>

          <strong v-if="item.is_campaign === 1
                      && checkDateBetween(item.campaign_start_datetime, item.campaign_end_datetime)">
            <template v-if="item.point_type === 2">{{ item.campaign_pointback_rate | numberWithCommas(',') }}<span>%</span>P</template>
            <template v-else>{{ item.campaign_pointback_amount | numberWithCommas(',', 'P') }}</template>
          </strong>
          <strong v-else>
            <template v-if="item.point_type === 2">{{ item.normal_pointback_rate | numberWithCommas(',') }}<span>%</span>P</template>
            <template v-else>{{ item.normal_pointback_amount | numberWithCommas(',', 'P') }}</template>
          </strong>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import Common from '@/mixins/common.mixin'

export default {
  name: 'ListCategory',

  mixins: [Common],

  props: {
    typeColor: {
      type: String,
      default: null
    },

    list: {
      type: Array,
      default: () => []
    }
  },

  created () {
    this.classTypeColor = this.typeColor ? this.typeColor : ''
  },

  data () {
    return {
      classTypeColor: null
    }
  }
}
</script>

<style lang="scss" scoped>
.list-category {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -4px 120px;
  @media #{$info-phone} {
    margin: 0 0 36px;
  }
  &.list-cate-like {
    margin: 0 -4px 125px;
    @media #{$info-phone} {
      margin: 0 0 29px;
    }
  }
  .block-category {
    max-width: calc(25% - 8px);
    margin: 0 4px 8px;
    width: 100%;
    position: relative;
    z-index: 1;
    padding: 16px;
    background: #E7E8DE;
    border-radius: 6px;
    min-height: 346px;
    @media #{$info-phone} {
      min-height: 131px;
      display: flex;
      padding: 9px;
    }
    .cate-pink & {
      background: #F1E9EB;
    }
    .cate-orange & {
      background: #F5F0ED;
    }
    &:nth-child(2n) {
      transform: translateY(24px);
      @media #{$info-tablet-horizontal} {
        transform: translateY(0);
      }
      @media #{$info-tablet-vertical} {
        transform: translateY(24px);
      }
      @media #{$info-phone} {
        transform: translateY(0);
      }
    }
    &:nth-child(3n + 2) {
      @media only screen and (min-width: 900px) and (max-width: 1160px) {
        transform: translateY(24px);
      }
    }
    @media #{$info-tablet-horizontal} {
      max-width: calc(33.3% - 8px);
    }
    @media #{$info-tablet-vertical} {
      max-width: calc(50% - 8px);
    }
    @media #{$info-phone} {
      max-width: 100%;
      margin: 0 0 7px;
    }
    .bg-shadow {
      position: absolute;
      right: 16px;
      top: 16px;
      border-radius: 6px;
      width: 144px;
      height: 144px;
      background: #BDBFAE;
      z-index: -1;
      opacity: .25;
      @media #{$info-phone} {
        width: 95px;
        height: 95px;
        right: unset;
        left: 18px;
        top: 9px;
      }
      .cate-pink & {
        background: #E5CADB;
      }
      .cate-orange & {
        background: #D98F6D;
        opacity: .1;
      }
    }
    .mark-cate {
      position: absolute;
      width: 83px;
      height: 83px;
      //object-fit: cover;
      right: 10px;
      top: 11px;
      margin: 0;
      background: transparent;
      @media #{$info-phone} {
        width: 52px;
        height: 52px;
        right: 5px;
        top: 5px;
        opacity: .5;
      }
    }
    img {
      display: block;
      width: 172px;
      height: 172px;
      margin: 0 auto 16px;
      object-fit: contain;
      background: #ffffff;
      @media #{$info-phone} {
        width: 100px;
        height: 100px;
        margin: auto;
      }
    }
    .content-cate {
      @media #{$info-phone} {
        flex: 1;
        margin-left: 20px;
      }
      h4 {
        font-family: $font-family-title-B;
        @include font-size(16px);
        margin-bottom: 8px;
        color: #7C934C;
        word-break: break-all;
        line-height: 1.3;
        @media #{$info-phone} {
          margin:7px 0;
          font-size: 16px;
        }
        .cate-pink & {
          color: #A54B67;
        }
        .cate-orange & {
          color: #C66C44;
        }
      }
      .description {
        color: #282828;
        @include font-size(12px);
        margin-bottom: 36px;
        word-break: break-all;
        @media #{$info-phone} {
          font-size: 13px;
          margin-bottom: 40px;
        }
      }
      .point-back {
        position: absolute;
        bottom: 25px;
        right: 15px;
        @media #{$info-phone} {
          right: 9px;
          bottom: 8px;
        }
      }
      p {
        color: #282828;
        @include font-size(12px);
        text-align: right;
        @media #{$info-phone} {
          font-size: 10px;
        }
      }
      strong {
        font-family: $font-label;
        color: #6F8784;
        @include font-size(36px);
        font-style: italic;
        font-weight: normal;
        line-height: 1;
        display: block;
        text-align: right;
        @media #{$info-phone} {
          font-size: 32px;
        }
        .cate-pink & {
          color: #C47E99;
        }
        .cate-orange & {
          color: #D98F6D;
        }
        span {
          @include font-size(21px);
          @media #{$info-phone} {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
